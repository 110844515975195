<template>
  <div>
    <!-- Container principale (BootstrapVue) -->
    <b-container fluid class="landing-container text-white">
      <!-- Hero Section -->
      <b-container
        fluid
        class="hero-section position-relative d-flex flex-column justify-content-center align-items-center text-center py-5"
        style="min-height: 100vh"
      >
        <!-- Background Hero -->
        <img
          loading="lazy"
          src="/assets/img/landing/showup_img_hero.webp"
          class="hero-image w-100 h-100"
          alt=""
        />

        <div class="hero-background"></div>
        <div class="hero-gradient"></div>

        <!-- Contenuto Hero -->
        <div class="hero-content position-relative d-flex flex-column align-items-center">
          <img
            loading="lazy"
            src="/assets/img/logo.svg"
            class="company-logo mb-5"
            alt="Show Up Productions Logo"
            style="padding-bottom: 30%"
          />
          <div class="hero-text mt-5">
            <h1 class="hero-title display-4 fw-bold">
              Transform Your Entertainment with Show Up Productions
            </h1>
            <p class="hero-subtitle fs-5 mt-3 mx-auto" style="padding-bottom: 15%">
              Attract new guests, retain your existing ones, enhance your reputation, and drive
              higher yearly revenue!
            </p>
          </div>
        </div>
      </b-container>

      <!-- Main Content: utilizzo di un container interno -->
      <b-container fluid class="main-content">
        <!-- CTA Image Box -->
        <b-row class="cta-video-box">
          <vimeo-player
            video-id="1051857742"
            class="video-player"
            ref="player2"
            :options="{
              title: false,
              muted: true,
              loop: true,
              controls: true,
              autoplay: true,
              responsive: true,
            }"
          ></vimeo-player>
        </b-row>
        <!-- CTA Section -->
        <section class="cta-section text-center mb-5 d-flex justify-content-center flex-column">
          <!-- CTA Button che porta a /form -->
          <div class="row d-flex justify-content-center">
            <div class="col-12 col-md-12 col-lg-3">
              <button class="btn btn-light cta-button fw-medium px-4 my-5" @click="goToForm">
                Book A Free Call Today!
              </button>
            </div>
          </div>

          <!-- Sezione con sfondo scuro -->
          <div
            class="p-lg-5 p-4"
            style="background-color: #191919; border: 1px solid #c6c6c6; border-radius: 16px"
          >
            <div class="container-fluid h-100">
              <div class="row d-flex justify-content-center align-items-center h-100">
                <div class="col-sm-12 col-md-12 col-lg-9">
                  <div class="intro-text-wrapper mx-auto">
                    <!-- Intro Text -->
                    <p class="intro-text fs-4 text-white text-left mb-0">
                      In today's fast-paced world, capturing attention is more challenging than
                      ever. This trend holds especially true in the realm of hospitality.
                      <br /><br />
                      During their holidays, people crave
                      <span class="font-weight-bold"
                        >fun, engaging, and unforgettable experiences</span
                      >
                      — the kind they eagerly share with friends and flaunt on social media.
                      <br /><br />If your entertainment offerings don't leave them awestruck, you
                      risk falling short…
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div class="container-fluid">
            <div class="row align-items-center pt-5">
              <div class="col-12 col-md-8 col-lg-10 offset-lg-1 text-left text-lg-center">
                <h1 class="service-head display-5">
                  This is What Happens When People Book Their Holidays Now…
                </h1>
              </div>
            </div>
            <div class="row align-items-center">
              <div class="col-12 col-md-8 col-lg-10 offset-md-1 text-start text-lg-center">
                <p class="service-sub fs-4 mt-3">
                  Entertainment plays a crucial role in their decision-making process…
                </p>
              </div>
            </div>
          </div>
        </section>

        <!-- Services Grid -->
        <b-row class="container-fluid my-5">
          <b-col sm="12" md="4">
            <div class="service-card pb-4 pb-lg-0">
              <!-- Prima foto senza margin-top aggiuntivo, gestito dal layout della card -->
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/3e7be2b0c29ccb2ceb2e0224b3e3a75b98b57147f7c986748edb8a13bf7cbdae?placeholderIfAbsent=true&apiKey=4470706791e7431fbb6c8cda4bdffef0"
                class="service-image img-fluid"
                alt="Hotels and Resorts Entertainment Services"
              />
              <div class="service-content pt-3 px-3">
                <h2 class="service-title fs-2 fw-bold mb-3">Cruises</h2>
                <p class="service-description fs-5">
                  Travelers look for a mix of relaxation and excitement. They seek cruises offering
                  both serene experiences and thrilling nightly shows.<br /><br />The promise of
                  spectacular entertainment on the high seas is often the deciding factor for
                  choosing a particular cruise.
                </p>
              </div>
            </div>
          </b-col>

          <b-col sm="12" md="4">
            <div class="service-card pb-4 pb-lg-0">
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/424156ece3252c575c72941ea0d28134a1b44611895ed02c7e931c04b9574d91?placeholderIfAbsent=true&apiKey=4470706791e7431fbb6c8cda4bdffef0"
                class="service-image img-fluid"
                alt="Cruise Entertainment Services"
              />
              <div class="service-content pt-3 px-3">
                <h2 class="service-title fs-2 fw-bold mb-3">Hotels and Resorts</h2>
                <p class="service-description fs-5">
                  Potential guests extensively browse online for videos and pictures. They look
                  beyond the aesthetic appeal of rooms and lobbies to the vibrancy of the
                  entertainment options.<br /><br />If the holiday experience appears boring and
                  static, they quickly opt for more engaging alternatives, prioritizing places with
                  dynamic activities and shows.
                </p>
              </div>
            </div>
          </b-col>

          <b-col sm="12" md="4">
            <div class="service-card">
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/17bb08b914366b641820f4a32e4f0eb3607c16371b24e35d0c1fb0e8c45a66b8?placeholderIfAbsent=true&apiKey=4470706791e7431fbb6c8cda4bdffef0"
                class="service-image img-fluid"
                alt="Theme Park Entertainment Services"
              />
              <div class="service-content pt-3 px-3">
                <h2 class="service-title fs-2 fw-bold mb-3">Theme Parks</h2>
                <p class="service-description fs-5">
                  Visitors choose parks based on the quality of attractions and live entertainment.
                  If the shows are of poor quality or repetitive, the park risks losing repeat
                  visitors and deterring new guests.
                  <br /><br />
                  High-quality, diverse entertainment is essential to maintaining a park's appeal
                  and ensuring visitors return year after year.
                </p>
              </div>
            </div>
          </b-col>
        </b-row>

        <!-- Divider -->
        <hr class="section-divider mx-auto my-5" />

        <!-- Challenges Section -->
        <section class="challenges-section my-5 pt-5">
          <!-- Titolo centrato -->
          <b-row class="d-flex justify-content-center">
            <b-col md="12" lg="12" cols="12">
              <h2 class="section-title display-5 text-left text-lg-center">
                What if You're Not Offering Great Entertainment or, Worse, Don't Have Entertainment
                At All?
              </h2>
            </b-col>
          </b-row>
          <b-row class="d-flex justify-content-center">
            <b-col md="9" lg="10" cols="12">
              <!-- Testo centrato -->
              <p class="section-subtitle fs-4 mt-4 text-left text-lg-center">
                In The Competitive World of Travel And Hospitality, Failing to Offer Great
                Entertainment Can Lead to a Host of Negative Outcomes For Your Business…
              </p>
            </b-col>
          </b-row>

          <!-- Challenges Grid: ridotto il padding da p-4 a p-2 -->

          <div class="challenges-grid my-5 ml-lg-5 ml-0">
            <div class="row justify-content-center">
              <!-- Aggiunta di justify-content-center alla riga -->
              <!-- Card 1 -->
              <div class="col-12 col-lg-4 col-md-4 mb-4">
                <div
                  class="challenge-card d-flex flex-column align-items-start text-left pb-5 pb-md-0"
                >
                  <div class="row">
                    <div class="col-8 d-flex align-items-center">
                      <img
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/5e37e33bfe3d8bfc9a9c00cba8c5d71c141011196162fdce7966cdc9b82c3c1b?placeholderIfAbsent=true&apiKey=4470706791e7431fbb6c8cda4bdffef0"
                        class="challenge-icon mb-2"
                        alt="Reputation Icon"
                      />
                    </div>

                    <div class="col-12 col-md-12 col-lg-7">
                      <h3 class="challenge-title mb-2 font-weight-normal">
                        Damaged Reputation and Awareness
                      </h3>
                    </div>

                    <div class="col-12 col-md-12 col-lg-9">
                      <p class="challenge-description mb-0">
                        Entertainment shapes guest satisfaction and brand perception.<br /><br />
                        Poor shows lead to negative reviews, reduced visibility, and missed
                        marketing opportunities like social media buzz.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Card 2 -->
              <div class="col-12 col-lg-4 col-md-4 mb-4">
                <div
                  class="challenge-card d-flex flex-column align-items-start text-left pb-5 pb-md-0"
                >
                  <div class="row">
                    <div class="col-8 d-flex align-items-center">
                      <img
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/60ed6e56ef5ff4c1d9a122ddb5cd40a9e1c6a6eba0dd13988b48832ec54b304d?placeholderIfAbsent=true&apiKey=4470706791e7431fbb6c8cda4bdffef0"
                        class="challenge-icon mb-2"
                        alt="Competition Icon"
                      />
                    </div>

                    <div class="col-12 col-md-12 col-lg-7">
                      <h3 class="challenge-title mb-2 font-weight-normal">Losing to Competitors</h3>
                    </div>

                    <div class="col-12 col-md-12 col-lg-9">
                      <p class="challenge-description mb-0">
                        Guests seek exciting experiences.<br /><br />
                        Without standout entertainment, they'll choose competitors who deliver,
                        leaving your business struggling to keep up.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Card 3 -->
              <div class="col-12 col-lg-4 col-md-4 mb-4">
                <div
                  class="challenge-card d-flex flex-column align-items-start text-left pb-5 pb-md-0"
                >
                  <div class="row">
                    <div class="col-8 d-flex align-items-center">
                      <img
                        loading="lazy"
                        src="/assets/icon/showup_icon_loyalty.svg"
                        class="challenge-icon mb-2"
                        alt="Loyalty Icon"
                      />
                    </div>

                    <div class="col-12 col-md-12 col-lg-9">
                      <h3 class="challenge-title mb-2 font-weight-normal">
                        Reduced Loyalty and Repeat Visits
                      </h3>
                    </div>

                    <div class="col-12 col-md-12 col-lg-9">
                      <p class="challenge-description mb-0">
                        Engaging entertainment creates lasting memories and encourages loyalty.<br /><br />
                        Without it, guests are less likely to return, impacting long-term success.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <!-- Revenue Impact -->
        <section class="revenue-impact p-5" style="background-color: #191919">
          <div class="container-fluid my-md-5">
            <div class="row d-flex align-items-center justify-content-around">
              <!-- Immagine -->
              <div class="col-12 col-md-3 col-lg-2 m-lg-5 mb-5">
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/347c32c450c403e3f52baef8472a0f6b3eba1bf4de143846656cbf02ff8ff679?placeholderIfAbsent=true&apiKey=4470706791e7431fbb6c8cda4bdffef0"
                  class="revenue-image img-fluid"
                  alt="Revenue Impact Illustration"
                />
              </div>
              <!-- Contenuto -->
              <div class="col-12 col-md-8 col-lg-5 revenue-content text-left">
                <h1 class="revenue-title display-4 font-weight-normal">
                  And This All Means... Decreased Revenue!
                </h1>
                <p class="revenue-description mt-3">
                  Failing to provide great entertainment leads to a cascade of negative effects, all
                  culminating in decreased revenue. All these factors directly impact your bottom
                  line, causing a significant drop in revenue.
                  <br /><br />
                  Ensuring you have top-tier entertainment is crucial to keep your business thriving
                  and profitable. And that’s the main reason you should rely on the best
                  entertainment professionals worldwide!
                </p>
              </div>
            </div>
          </div>
        </section>

        <!-- Partnership Section -->
        <section class="partner-section">
          <div class="row pb-md-5 py-5">
            <div class="col-12 col-md-12 col-lg-7 mx-auto text-left text-lg-center">
              <h2 class="display-4 fw-bold" style="padding-top: 10%">
                Partner with<br />Show Up Productions!
              </h2>
            </div>
          </div>
          <div class="row partner-card py-5">
            <div class="col-6 d-none d-md-block"></div>
            <div class="col-12 col-md-4 d-flex flex-row partner-content">
              <p class="partner-description text-left">
                With over <span class="font-weight-bold">30 years of experience</span>, Show Up
                Productions creates unforgettable entertainment that captivates audiences and
                elevates brands. Known for our
                <span class="font-weight-bold">innovation and excellence</span>, we transform
                ordinary events into extraordinary experiences, driving
                <span class="font-weight-bold">guest engagement and loyalty.</span>
              </p>
            </div>
          </div>
        </section>

        <!-- Benefits Section -->
        <section class="benefits-section">
          <div class="benefits-header p-4 p-lg-5 my-5">
            <div class="row mt-5">
              <div class="col-12 col-md-12 col-lg-12">
                <h1 class="benefits-title display-5 fw-medium text-left text-lg-center">
                  Let Us Do The Heavy Lifting While You and Your Guests Enjoy The Best Experience
                  Ever!
                </h1>
              </div>
            </div>
            <div class="row mb-5">
              <div class="col-12 col-md-8 col-lg-12">
                <h5 class="benefits-subtitle fs-4 mt-3 left text-lg-center">
                  With our extensive experience, Show Up Productions expertly manages every aspect
                  of your entertainment project, ensuring a seamless and spectacular show.
                  <br /><br />You can focus on your core business while we handle the rest!
                </h5>
              </div>
            </div>
          </div>

          <!-- Grid di benefit -->
          <b-row class="benefits-grid g-4 mt-4">
            <!-- Benefit 1 -->
            <b-col cols="12" md="4">
              <div class="benefit-card text-start px-3 py-4">
                <img
                  loading="lazy"
                  src="/assets/img/landing/partner/showup_img_04.webp"
                  class="benefit-icon mb-3"
                  alt="Custom Show Design Icon"
                  style="width: 100%"
                />
                <b-row fluid class="px-4">
                  <h2 class="benefit-title fs-3 fw-bold">Custom Show Design</h2>
                  <p class="benefit-description fs-6 mt-2">
                    We create unique shows tailored to your audience and brand, ensuring a
                    captivating performance every time.
                  </p>
                </b-row>
              </div>
            </b-col>

            <!-- Benefit 2 -->
            <b-col cols="12" md="4">
              <div class="benefit-card text-start px-3 py-4">
                <img
                  loading="lazy"
                  src="/assets/img/landing/partner/showup_img_05.webp"
                  class="benefit-icon mb-3"
                  alt="Talent Management Icon"
                  style="width: 100%"
                />
                <b-row fluid class="px-4">
                  <h2 class="benefit-title fs-3 fw-bold">Talent Management</h2>
                  <p class="benefit-description fs-6 mt-2">
                    From recruiting to training, we handle all aspects of artist management,
                    bringing together top-tier talent for your shows.
                  </p>
                </b-row>
              </div>
            </b-col>

            <!-- Benefit 3 -->
            <b-col cols="12" md="4">
              <div class="benefit-card text-start px-3 py-4">
                <img
                  loading="lazy"
                  src="/assets/img/landing/partner/showup_img_06.webp"
                  class="benefit-icon mb-3"
                  alt="Logistics Icon"
                  style="width: 100%"
                />
                <b-row fluid class="px-4">
                  <h2 class="benefit-title fs-3 fw-bold">Logistics</h2>
                  <p class="benefit-description fs-6 mt-2">
                    Our team coordinates all logistics, including transportation, setup, and
                    teardown, ensuring a smooth operation without any disruptions.
                  </p>
                </b-row>
              </div>
            </b-col>

            <!-- Benefit 4 -->
            <b-col cols="12" md="4">
              <div class="benefit-card text-start px-3 py-4">
                <img
                  loading="lazy"
                  src="/assets/img/landing/partner/showup_img_07.webp"
                  class="benefit-icon mb-3"
                  alt="Production Management Icon"
                  style="width: 100%"
                />
                <b-row fluid class="px-4">
                  <h2 class="benefit-title fs-3 fw-bold">Production Management</h2>
                  <p class="benefit-description fs-6 mt-2">
                    We oversee every detail of the production, from lighting and sound to set and
                    costume design, guaranteeing a high-quality show.
                  </p>
                </b-row>
              </div>
            </b-col>

            <!-- Benefit 5 -->
            <b-col cols="12" md="4">
              <div class="benefit-card text-start px-3 py-4">
                <img
                  loading="lazy"
                  src="/assets/img/landing/partner/showup_img_08.webp"
                  class="benefit-icon mb-3"
                  alt="Paperwork and Compliance Icon"
                  style="width: 100%"
                />
                <b-row fluid class="px-4">
                  <h2 class="benefit-title fs-3 fw-bold">Paperwork and Compliance</h2>
                  <p class="benefit-description fs-6 mt-2">
                    We take care of permits and legal requirements and ensure all necessary
                    paperwork is completed accurately and on time.
                  </p>
                </b-row>
              </div>
            </b-col>

            <!-- Benefit 6 -->
            <b-col cols="12" md="4">
              <div class="benefit-card text-start px-3 py-4">
                <img
                  loading="lazy"
                  src="/assets/img/landing/partner/showup_img_09.webp"
                  class="benefit-icon mb-3"
                  alt="Artistic Direction Icon"
                  style="width: 100%"
                />
                <b-row fluid class="px-4">
                  <h2 class="benefit-title fs-3 fw-bold">Artistic Direction</h2>
                  <p class="benefit-description fs-6 mt-2">
                    Our experts provide creative guidance, ensuring every element of the show aligns
                    perfectly with your vision and enhances the overall experience.
                  </p>
                </b-row>
              </div>
            </b-col>
          </b-row>

          <!-- CTA al termine dei benefit -->
          <b-col fluid class="text-center">
            <b-button variant="light" class="cta-button fw-medium px-4 py-2 my-3" @click="goToForm">
              Book A Free Call Today!
            </b-button>
          </b-col>
        </section>

        <!-- Team Section -->
        <section class="team-section text-center my-5">
          <b-col fluid class="team-header mx-auto text-left" style="padding-top: 5%">
            <h1 class="team-title display-5 fw-medium">Already Have An<br />In-House Team?</h1>
            <h2 class="team-subtitle fs-3 mt-3">Let Us Enhance Your Efforts!</h2>
            <hr class="section-divider mx-auto my-4" />
          </b-col>

          <b-col lg="4" md="8" cols="12">
            <p class="team-description fs-4 text-left">
              Let us join forces with your team, seamlessly integrating into their process to
              support one or multiple aspects of your production. While your talent focuses on
              delivering outstanding performances, we'll handle the behind-the-scenes to ensure
              flawless execution and exceptional results!
            </p>
          </b-col>

          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/73f5261d543438bbbfaab81ab6432d5a8a1790452cac0bc409a7ed3f110c4673?placeholderIfAbsent=true&apiKey=4470706791e7431fbb6c8cda4bdffef0"
            class="team-image img-fluid mt-5"
            alt="Team Collaboration Illustration"
            style="border-radius: 28px"
          />

          <h2 class="final-cta display-5 fw-medium">
            Book your free consultation with us and discover another new level of entertainment and
            capitalization!
          </h2>

          <div
            class="final-cta-container d-flex flex-column flex-md-row align-items-center justify-content-center gap-3 mt-5"
          >
            <b-button variant="light" class="cta-button fw-medium px-4 py-2 mx-5" @click="goToForm">
              Book A Free Call Today!
            </b-button>
            <div
              class="cta-divider d-none d-md-block"
              style="width: 1px; height: 77px; background-color: #c6c6c6"
            ></div>
            <p
              class="cta-text fs-6 text-center text-md-left mb-0 ml-0 ml-md-5 mt-2 mt-md-0"
              style="max-width: 320px"
            >
              Attract new guests, retain your existing ones, enhance your reputation, and drive
              higher yearly revenue!
            </p>
          </div>
        </section>
      </b-container>

      <footer-row class="footer"></footer-row>
    </b-container>
  </div>
</template>

<script>
import Vue from 'vue';
import Footer from '@/components/footer.vue';

export default Vue.extend({
  name: 'LandingPage',
  components: {
    'footer-row': Footer,
  },
  mounted() {
    this.onResize();
  },
  created() {
    window.addEventListener('resize', this.onResize);
    this.onResize();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    // -- Metodo per andare a /form
    goToForm() {
      this.$router.push('/form');
    },
  },
});
</script>
